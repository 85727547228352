/* eslint-disable prettier/prettier */
type User = {
  userId: string;
  email: string;
};

import { initializeApp } from "firebase/app";
import { UserCredential, getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCFCElN7-yShTmAVfy7cymC39kk8VDu4qY",
  authDomain: "conroo-staging.firebaseapp.com",
  projectId: "conroo-staging",
  storageBucket: "conroo-staging.appspot.com",
  messagingSenderId: "177375273751",
  appId: "1:177375273751:web:fd89fa5f709906e0944d52",
  measurementId: "G-6JB4Z32G2E"
};

const app = initializeApp(firebaseConfig);


export interface Authenticator {
  login(email: string, password: string): Promise<User>;
  logout(): Promise<void>;
  getCurrentUser() : User | null;
  getAccessToken(): Promise<string | undefined>;
}


export const getAuthService = () : Authenticator => {
  const firebaseAuth =  getAuth(app);
  return {
    login : async (email : string,password:string) => {
    const result  = await signInWithEmailAndPassword(firebaseAuth,email,password);

    return {
      userId:result.user.uid,
      email,
    }
    },
    logout: async () => {
      await signOut(firebaseAuth);
    },
    getCurrentUser:  () => {
     const currentUser =  firebaseAuth.currentUser;
      if(currentUser === null) {
        return null;
      }

      return {
        email:currentUser.email ?? "",
        userId:currentUser.uid
      };

    },
    getAccessToken: async () => {
      await firebaseAuth.authStateReady();
      const currentUser = firebaseAuth.currentUser;
      return await currentUser?.getIdToken();
    }
  }
}
