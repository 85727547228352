<template>
  <dialog
    id="confirmation_modal"
    class="rounded"
    data-testid="test-confirmModal"
  >
    <div class="flex flex-col justify-center p-4">
      <div class="flex flex-col p-5">
        <div class="text-xl md:text-2xl text-center mb-5">
          {{ customText }}
        </div>
        <div class="flex justify-between">
          <button
            @click="() => sendSubmit()"
            class="btn btn-outline btn-sm mr-2 bg-red"
          >
            {{ customSubmit }}
          </button>
          <button @click="() => closeDialog()" class="btn btn-info btn-sm mr-2">
            {{ customCancel }}
          </button>
        </div>
      </div>
    </div>
  </dialog>
</template>

<script lang="ts" setup>
import { defineProps, watch, defineEmits } from "vue";

const props = defineProps({
  userId: {
    type: String,
    required: true,
  },
  customText: {
    type: String,
    required: true,
  },
  customCancel: {
    type: String,
    required: false,
    default: "Cancel",
  },
  customSubmit: {
    type: String,
    required: false,
    default: "Submit",
  },
  openDialog: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits<{
  submit: [payload: { id: string }];
}>();

watch(
  () => props.openDialog,
  async (_) => {
    const dialog = document.getElementById("confirmation_modal");
    (dialog as HTMLDialogElement)?.showModal();
  }
);

const sendSubmit = () => {
  closeDialog();
  emit("submit", { id: props.userId });
};

const closeDialog = () => {
  const dialog = document.getElementById("confirmation_modal");
  (dialog as HTMLDialogElement)?.close();
};
</script>
