<template>
  <div>
    <div v-if="userSelectedId.length !== 0">
      <ConfirmationDialog
        :user-id="userSelectedId"
        @submit="(t) => resetUser(t)"
        custom-text="Resetting IDnow will grant the user additional verification attempts. Please ensure that this is justified."
        custom-submit="Accept"
        :open-dialog="openResetDialog"
      ></ConfirmationDialog>
      <HistoryDialog
        @approve="(t) => approveUser(t, userSelectedId)"
        :open-dialog="openHistoryDialog"
        :users="currentUser.changes"
      ></HistoryDialog>
    </div>
    <table class="table">
      <!-- head -->
      <thead>
        <tr>
          <th>UserId</th>
          <th>Account type</th>
          <th>Email</th>
          <th>Phone number</th>
          <th>Terror state</th>
          <th>Document state</th>
          <th>First name</th>
          <th>Last name</th>
          <th>Birth</th>
          <th>Verification started</th>
          <th>last_edited_date</th>
        </tr>
      </thead>
      <tbody>
        <!-- row 1 -->
        <tr
          :class="{
            'bg-orange-400':
              getLastArrayElement(user.truckerIdentification!)
                ?.documentStatus === 'WARN',
            'bg-red-400': user.changes?.filter((e) => e.state === 'PENDING').length !== 0,
          }"
          v-for="user in props.users"
          :key="user.userId"
        >
          <td>
            {{ user.userId }}
          </td>
          <td>
            {{ user.phoneNumber != null ? "PHONE" : "EMAIL" }}
          </td>
          <td>{{ user.email ?? "missing" }}</td>
          <td>
            {{ user.phoneNumber ?? "missing" }}
          </td>
          <td>
            <EditableDropdown
              :selected-value="changeToSend.terrorStatus"
              @select="(t) => (changeToSend.terrorStatus = t)"
              :isEditable="currentlyEditableRow === user.userId"
              :entries="validTerrorStates"
              :title="
                getLastArrayElement(user.truckerIdentification!)?.terrorStatus ??
                ''
              "
            >
            </EditableDropdown>
          </td>
          <td>
            <EditableDropdown
              :selected-value="changeToSend.documentStatus"
              @select="(t) => (changeToSend.documentStatus = t)"
              :isEditable="currentlyEditableRow === user.userId"
              :entries="validIdentificationStates"
              :title="
                getLastArrayElement(user.truckerIdentification!)
                  ?.documentStatus ?? ''
              "
            >
            </EditableDropdown>
          </td>
          <td>
            <EditableInput
              :title="user.firstName"
              @update:model-value="(t) => (changeToSend.firstName = t)"
              :isEditable="currentlyEditableRow === user.userId"
              :inputValue="changeToSend.firstName"
              placeholder="firstname"
            ></EditableInput>
          </td>
          <td>
            <EditableInput
              :title="user.lastName"
              @update:model-value="(t) => (changeToSend.lastName = t)"
              :isEditable="currentlyEditableRow === user.userId"
              :inputValue="changeToSend.lastName"
              placeholder="lastname"
            ></EditableInput>
          </td>
          <td>{{ getBirthday(user) }}</td>
          <td>{{ getVerificationDate(user) }}</td>
          <td>{{ getLastEditedDate(user) }}</td>
          <th>
            <div
              v-if="currentlyEditableRow !== user.userId"
              class="flex flex-row space-x-4"
            >
              <div class="tooltip" data-tip="Edit">
                <div @click="() => changeCurrentlyEditable(user.userId!)">
                  <svg
                    class="fill-black-500 cursor-pointer hover:fill-black-800"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M7.127 22.562l-7.127 1.438 1.438-7.128 5.689 5.69zm1.414-1.414l11.228-11.225-5.69-5.692-11.227 11.227 5.689 5.69zm9.768-21.148l-2.816 2.817 5.691 5.691 2.816-2.819-5.691-5.689z"
                    />
                  </svg>
                </div>
              </div>
              <div class="tooltip tooltip-left" data-tip="Show user history">
                <div @click="() => showUserHistory(user.userId!)">
                  <svg
                    class="cursor-pointer fill-black-500"
                    height="24"
                    width="24"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 219.15 219.15"
                    xml:space="preserve"
                  >
                    <g>
                      <path
                        d="M109.575,0C49.156,0,0.001,49.155,0.001,109.574c0,60.42,49.154,109.576,109.573,109.576
		c60.42,0,109.574-49.156,109.574-109.576C219.149,49.155,169.995,0,109.575,0z M109.575,204.15
		c-52.148,0-94.573-42.427-94.573-94.576C15.001,57.426,57.427,15,109.575,15c52.148,0,94.574,42.426,94.574,94.574
		C204.149,161.724,161.723,204.15,109.575,204.15z"
                      />
                      <path
                        d="M166.112,108.111h-52.051V51.249c0-4.142-3.357-7.5-7.5-7.5c-4.142,0-7.5,3.358-7.5,7.5v64.362c0,4.142,3.358,7.5,7.5,7.5
		h59.551c4.143,0,7.5-3.358,7.5-7.5C173.612,111.469,170.254,108.111,166.112,108.111z"
                      />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="tooltip" data-tip="Reset IDnow tries">
                <div @click="() => showResetUser(user.userId!)">
                  <svg
                    class="cursor-pointer fill-black-500"
                    height="24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div class="flex flex-row space-x-4 cursor-pointer" v-else>
              <div class="tooltip" data-tip="Save">
                <div @click="() => applyChanges()">
                  <svg
                    class="fill-green-500 hover:fill-green-900"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50 50"
                    width="24px"
                    height="24px"
                  >
                    <path
                      d="M 42.875 8.625 C 42.84375 8.632813 42.8125 8.644531 42.78125 8.65625 C 42.519531 8.722656 42.292969 8.890625 42.15625 9.125 L 21.71875 40.8125 L 7.65625 28.125 C 7.410156 27.8125 7 27.675781 6.613281 27.777344 C 6.226563 27.878906 5.941406 28.203125 5.882813 28.597656 C 5.824219 28.992188 6.003906 29.382813 6.34375 29.59375 L 21.25 43.09375 C 21.46875 43.285156 21.761719 43.371094 22.050781 43.328125 C 22.339844 43.285156 22.59375 43.121094 22.75 42.875 L 43.84375 10.1875 C 44.074219 9.859375 44.085938 9.425781 43.875 9.085938 C 43.664063 8.746094 43.269531 8.566406 42.875 8.625 Z"
                    />
                  </svg>
                </div>
              </div>

              <div class="tooltip" data-tip="Discard">
                <div @click="() => discardChanges()">
                  <svg
                    class="fill-red-500 hover:fill-red-900 mt-1"
                    height="18px"
                    width="18px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 460.775 460.775"
                    xml:space="preserve"
                  >
                    <path
                      d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55
	c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55
	c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505
	c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55
	l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719
	c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import { ref, defineProps, defineEmits, reactive, computed } from "vue";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import HistoryDialog from "./HistoryDialog.vue";
import { deleteAllKeys, getLastArrayElement } from "@/util/object-util";
import { convertTimeToLocalReadable } from "@/services/date/date-service";
import EditableDropdown from "./EditableDropdown.vue";
import EditableInput from "./EditableInput.vue";

import { terrorStates, idStates } from "./commons";

import {
  IspsTruckerChangeRequestTOTerrorStatusEnum,
  IspsTruckerChangeTO,
  IspsTruckerChangeTODocumentStatusEnum,
  IspsTruckerTO,
} from "@/services/api-client/client";
import { isAdmin } from "@/util/role-util";
import { useUserStore } from "@/observables/store/user-store";
import { useToastStore } from "@/observables/store/toast-store";

const validIdentificationStates: IspsTruckerChangeTODocumentStatusEnum[] = [
  "MANUALLY_APPROVED",
  "MANUALLY_DENIED",
];

const validTerrorStates: IspsTruckerChangeRequestTOTerrorStatusEnum[] = [
  "MANUALLY_APPROVED",
  "MANUALLY_DENIED",
];

const currentlyEditableRow = ref<string>("");
const userStore = useUserStore();
const toastStore = useToastStore();
const admin = userStore.getUser();
const userSelectedId = ref<string>("");

const openHistoryDialog = ref(false);
const openResetDialog = ref(false);

const currentUser = computed(
  () => props.users.filter((e) => e.userId === userSelectedId.value)[0]
);
const changeToSend = reactive<IspsTruckerChangeTO>({});

const changeCurrentlyEditable = (userId: string) => {
  currentlyEditableRow.value = userId;
  deleteAllKeys(changeToSend);
};

const discardChanges = () => {
  currentlyEditableRow.value = "";
  deleteAllKeys(changeToSend);
};

const emit = defineEmits<{
  submit: [payload: { userId: string; data: IspsTruckerChangeTO }];
  approve: [payload: { userId: string; entryId: number; approve: boolean }];
  reset: [payload: { userId: string }];
}>();

const getBirthday = (user: IspsTruckerTO): string => {
  //some util might be needed
  if (
    user.truckerIdentification == null ||
    user.truckerIdentification.length === 0
  ) {
    return "";
  }

  const result =
    user.truckerIdentification[
      user.truckerIdentification.length - 1
    ].dateOfBirth?.toISOString();

  if (result === undefined) {
    return "";
  }

  return convertTimeToLocalReadable(new Date(result));
};

const getVerificationDate = (user: IspsTruckerTO): string => {
  if (
    user.truckerIdentification == null ||
    user.truckerIdentification.length === 0
  ) {
    return "";
  }

  const result =
    user.truckerIdentification[
      user.truckerIdentification.length - 1
    ].verifiedDate?.toISOString();

  if (result === undefined) {
    return "";
  }

  return convertTimeToLocalReadable(new Date(result));
};

const getLastEditedDate = (user: IspsTruckerTO): string => {
  const lastChange = user.changes?.[user.changes.length - 1]?.createdDate;
  return lastChange ? convertTimeToLocalReadable(new Date(lastChange)) : "";
};

const isChangeValid = (change: IspsTruckerChangeTO): boolean => {
  return Object.keys(change).length !== 0;
};

const applyChanges = () => {
  const validChange = isChangeValid(changeToSend);
  if (!validChange) {
    toastStore.addToast({
      message: "Can't add an empty change",
      level: "error",
    });
    return;
  }
  const payload = {
    userId: currentlyEditableRow.value,
    data: { ...changeToSend },
  };
  emit("submit", payload);
  currentlyEditableRow.value = "";
  deleteAllKeys(changeToSend);
};

const showResetUser = (userId: string) => {
  userSelectedId.value = userId;
  openResetDialog.value = !openResetDialog.value;
};

const showUserHistory = (userId: string) => {
  userSelectedId.value = userId;
  openHistoryDialog.value = !openHistoryDialog.value;
};

const approveUser = (
  payload: { approve: boolean; id: number },
  userId: string
) => {
  const { approve, id } = payload;
  emit("approve", { userId, entryId: id, approve });
};

const resetUser = (payload: { id: string }) => {
  const { id } = payload;
  emit("reset", { userId: id });
};

const props = defineProps<{ users: IspsTruckerTO[] }>();
</script>
