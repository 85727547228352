<template>
  <div class="h-full">
    <div>
      <div class="navbar bg-[#0b295b] flex justify-between">
        <div class="flex">
          <a class="btn btn-ghost text-xl text-neutral-content">CONROO</a>
          <div class="text-neutral-content">
            {{
              `Current ${isAdmin(admin.roles ?? []) ? "Admin" : "User"}: ${
                admin.firstName
              } ${admin.lastName}`
            }}
          </div>
        </div>
        <div class="text-neutral-content">
          <button @click="() => logout()" class="btn btn-error btn-sm mr-2">
            Log out
          </button>
        </div>
      </div>
    </div>
    <div class="flex pt-12 justify-center flex-col h-full">
      <div class="text-4xl md:text-6xl ml-12 text-[#0b295b] font-bold">
        <h1>User Management</h1>
      </div>

      <div v-if="filledData" class="mt-4">
        <UserSearchBar @submit="(t) => filterUsers(t)"></UserSearchBar>
      </div>

      <div v-else>
        <div class="flex justify-center mt-24 pt-24">
          <span class="loading loading-spinner loading-lg"></span>
        </div>
      </div>
      <div v-if="toast.message">
        <div role="alert" :class="`alert ${toast.type}`">
          <span class="font-bold text-white opacity-100">{{
            toast.message
          }}</span>
        </div>
      </div>

      <div class="mt-8 overflow-x-scroll h-full" v-if="users.data.length !== 0">
        <div class="pl-8 pr-8" v-if="users.data.length !== 0">
          <ManagedUserTable
            @approve="(p) => approveUser(p)"
            @submit="(p) => addUserRevision(p)"
            @reset="(p) => resetIDNow(p)"
            :users="users.data"
          >
          </ManagedUserTable>
        </div>
      </div>
      <div v-if="users.data.length !== 0 && loading">
        <span class="loading loading-bars loading-lg"></span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ManagedUserTable from "./ManagedUserTable.vue";
import { reactive, onMounted, ref, onUnmounted } from "vue";
import UserSearchBar from "./UserSearchBar.vue";
import { useUserStore } from "@/observables/store/user-store";
import { getAuthService } from "@/services/auth/auth-service";
import { useRouter } from "vue-router";
import { getAdminClient } from "@/services/api-client";
import {
  IspsTruckerChangeTO,
  IspsTruckerChangeTODocumentStatusEnum,
  IspsTruckerChangeTOTerrorStatusEnum,
  IspsTruckerTO,
  TruckerIdentificationTODocumentStatusEnum,
  TruckerIdentificationTOTerrorStatusEnum,
} from "@/services/api-client/client";
import { usePagination } from "@/observables/pagination";
import { isAdmin } from "@/util/role-util";

interface Toast {
  message: string;
  toastType: "alert-success" | "alert-error" | "alert-warn" | "alert-info";
}

const userStore = useUserStore();
const authService = getAuthService();
const adminClient = getAdminClient();
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const admin = userStore.getUser()!;
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const adminId = admin.id!;
const toast = ref<Toast>({ message: "", toastType: "alert-info" });

const fetchTruckers = async (pageSize: number, pageIndex: number) => {
  const currentPageUsers = await adminClient.getIspsTruckers(
    adminId,
    searchPayload.warningState,
    searchPayload.terrorState,
    searchPayload.userId,
    pageIndex,
    pageSize
  );

  users.data.push(...currentPageUsers);
  if (currentPageUsers.length < pageSize) {
    return true;
  }

  return false;
};

const { pageSize, pageIndex, loading, restartPageIndex, incrementPageIndex } =
  usePagination(fetchTruckers);
const router = useRouter();

const filledData = ref<boolean>(false);
let intervalId: number | null = null;
const POLLING_TIME_MILLISECONDS = 1000 * 60; // 1 minute

let searchPayload: {
  userId: string | undefined;
  warningState: IspsTruckerChangeTODocumentStatusEnum | undefined;
  terrorState: IspsTruckerChangeTOTerrorStatusEnum | undefined;
} = reactive({
  userId: undefined,
  warningState: undefined,
  terrorState: undefined,
});

onMounted(async () => {
  await fetchTruckers(pageSize, pageIndex.value);
  incrementPageIndex();
  filledData.value = true;

  intervalId = setInterval(async () => {
    await filterUsers(searchPayload);
  }, POLLING_TIME_MILLISECONDS);
});

onUnmounted(() => {
  if (intervalId != null) {
    clearInterval(intervalId);
  }
});
const users = reactive<{ data: IspsTruckerTO[] }>({
  data: [],
});

const filterUsers = async (payload: {
  userId: string | undefined;
  terrorState: TruckerIdentificationTOTerrorStatusEnum | undefined;
  warningState: TruckerIdentificationTODocumentStatusEnum | undefined;
}) => {
  const { userId, terrorState, warningState } = payload;
  searchPayload = payload;
  //use pagination is not a good abstraction atm, share state in a readonly result, and provide an interface for functions to interact in a generic way
  restartPageIndex();
  const searchedUsers = await adminClient.getIspsTruckers(
    adminId,
    warningState,
    terrorState,
    userId?.trim(),
    pageIndex.value,
    pageSize
  );

  incrementPageIndex();
  users.data = searchedUsers;
};

const addUserRevision = async (payload: {
  userId: string;
  data: IspsTruckerChangeTO;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  await adminClient.ispsTruckerLog(adminId!, payload.userId, payload.data);
  //absolutely must be fixed, just a quick dirty code
  filterUsers({
    userId: undefined,
    terrorState: undefined,
    warningState: undefined,
  });
};

const showToast = (message: string, alertType: string) => {
  toast.value.message = message;
  toast.value.type = `alert-${alertType}`;
  setTimeout(() => {
    toast.value.message = "";
    toast.value.type = "alert-info";
  }, 3000);
};

const resetIDNow = async (payload: { userId: string }) => {
  try {
    await adminClient.deleteTruckerIdentifications(adminId!, payload.userId);
    showToast("IDnow verification attempts succesfully resetted", "success");
  } catch (e) {
    showToast("Error reseting IDnow tries for user", "error");
    console.error(e);
  }
};

//TODO: this should be for all pages, this is just simple enough for now.
const logout = async () => {
  await authService.logout();
  userStore.removeUser();
  router.push("/");
  //this sucks so much haha hehe haha
};

const approveUser = async (payload: {
  userId: string;
  entryId: number;
  approve: boolean;
}) => {
  const { userId, entryId, approve } = payload;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  //TODO: ISSUE WITH 204 - 200 fix in backend
  try {
    await adminClient.patchIspsTrucker(adminId!, userId, entryId, {
      approved: approve,
    });
  } catch (e) {
    console.log(e);
  }

  filterUsers({
    userId: undefined,
    terrorState: undefined,
    warningState: undefined,
  });
};
</script>
